import { React } from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import Logo1 from "../assets/images/LogoCNT.jpg";
import { tokens } from "../theme";

const Footer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const footerStyle = {
    backgroundColor: "#E5E5E5",
    color: "#000000",
    padding: "20px",
    marginTop: "auto",
  };
  const sectionStyle = {
    marginBottom: "10px",
  };
  const imageContainerStyle = {
    textAlign: "center",
  };
  const imageStyle = {
    maxWidth: "100%",
    maxHeight: "100px",
    margin: "auto",
  };
  return (
    <footer style={footerStyle}>
      <Grid container justify="space-between">
        <Grid item xs={12} sm={3} style={sectionStyle}>
          <Typography
            variant="h5"
            color={colors.blackAccent[900]}
            fontWeight="bold"
            sx={{ m: "0 0 5px 0" }}
          >
            Contáctanos
          </Typography>
          <Typography
            variant="h7"
            color={colors.blackAccent[900]}
            sx={{ m: "0 0 5px 0" }}
          >
            Teléfono: (+504) 2232-9600 <br />
          </Typography>
          <Typography
            variant="h7"
            color={colors.blackAccent[900]}
            sx={{ m: "0 0 5px 0" }}
          >
            Dirección: Edificio CONATEL, 6 Ave. S.O. Colonia Modelo <br />
            Comayaguela, M.D.C. Honduras Apdo. 15012
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} style={sectionStyle}></Grid>
        <Grid item xs={12} sm={3} style={sectionStyle}>
          <div style={imageContainerStyle}>
            <img src={Logo1} alt="Logo de la empresa" style={imageStyle} />
          </div>
        </Grid>
      </Grid>
    </footer>
  );
};
export default Footer;
