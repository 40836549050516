import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens export

export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        white: {
          100: "#ffffff",
          200: "#f2f2f2",
          300: "#e5e5e5",
          400: "#d9d9d9",
          500: "#cccccc",
          600: "#bfbfbf",
          700: "#b3b3b3",
          800: "#a6a6a6",
          900: "#999999",
        },
        blackAccent: {
          50: "#e0e0e0",
          100: "#c0c0c0",
          200: "#a0a0a0",
          300: "#808080",
          400: "#606060",
          500: "#404040",
          600: "#303030",
          700: "#202020",
          800: "#101010",
          900: "#000000",
        },
        grey: {
          100: "#e6e6e6",
          200: "#cccccc",
          300: "#b3b3b3",
          400: "#999999",
          500: "#808080",
          600: "#666666",
          700: "#4d4d4d",
          800: "#333333",
          900: "#1a1a1a",
        },
        primary: {
          100: "#e0f7fa",
          200: "#b2ebf2",
          300: "#80deea",
          400: "#4dd0e1",
          500: "#26c6da",
          600: "#00bcd4",
          700: "#00acc1",
          800: "#0097a7",
          900: "#00838f",
        },
        greenAccent: {
          100: "#e0f2f1",
          200: "#b2dfdb",
          300: "#80cbc4",
          400: "#4db6ac",
          500: "#26a69a",
          600: "#009688",
          700: "#00897b",
          800: "#00796b",
          900: "#00695c",
        },
        redAccent: {
          100: "#ffebee",
          200: "#ffcdd2",
          300: "#ef9a9a",
          400: "#e57373",
          500: "#ef5350",
          600: "#f44336",
          700: "#e53935",
          800: "#d32f2f",
          900: "#c62828",
        },
        blueAccent: {
          100: "#e3f2fd",
          200: "#bbdefb",
          300: "#90caf9",
          400: "#64b5f6",
          500: "#42a5f5",
          600: "#2196f3",
          700: "#1e88e5",
          800: "#1976d2",
          900: "#1565c0",
          950: "#8DCBD7",
        },
        gov: {
          50: "#f3f9fc",
          100: "#e6f3f8",
          200: "#c7e7f0",
          300: "#88cfe0",
          400: "#5dbed3",
          500: "#38a7bf",
          600: "#2888a1",
          700: "#216d83",
          800: "#1f5c6d",
          900: "#1f4d5b",
          950: "#15313c",
        },
      }
    : {
        white: {
          100: "#ffffff",
          200: "#f2f2f2",
          300: "#e5e5e5",
          400: "#d9d9d9",
          500: "#cccccc",
          600: "#bfbfbf",
          700: "#b3b3b3",
          800: "#a6a6a6",
          900: "#999999",
        },
        blackAccent: {
          50: "#e0e0e0",
          100: "#c0c0c0",
          200: "#a0a0a0",
          300: "#808080",
          400: "#606060",
          500: "#404040",
          600: "#303030",
          700: "#202020",
          800: "#101010",
          900: "#000000",
        },
        grey: {
          100: "#f5f5f5",
          200: "#eeeeee",
          300: "#e0e0e0",
          400: "#bdbdbd",
          500: "#9e9e9e",
          600: "#757575",
          700: "#616161",
          800: "#424242",
          900: "#212121",
        },
        primary: {
          100: "#e3f2fd",
          200: "#bbdefb",
          300: "#90caf9",
          400: "#64b5f6",
          500: "#42a5f5",
          600: "#2196f3",
          700: "#1e88e5",
          800: "#1976d2",
          900: "#1565c0",
        },
        greenAccent: {
          100: "#e8f5e9",
          200: "#c8e6c9",
          300: "#a5d6a7",
          400: "#81c784",
          500: "#66bb6a",
          600: "#4caf50",
          700: "#43a047",
          800: "#388e3c",
          900: "#2e7d32",
        },
        redAccent: {
          100: "#ffebee",
          200: "#ffcdd2",
          300: "#ef9a9a",
          400: "#e57373",
          500: "#ef5350",
          600: "#f44336",
          700: "#e53935",
          800: "#d32f2f",
          900: "#c62828",
        },
        blueAccent: {
          100: "#e3f2fd",
          200: "#bbdefb",
          300: "#90caf9",
          400: "#64b5f6",
          500: "#42a5f5",
          600: "#2196f3",
          700: "#1e88e5",
          800: "#1976d2",
          900: "#1565c0",
        },
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.blackAccent[900],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.white[100],
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: "#fcfcfc",
            },
          }),
    },
    typography: {
      fontFamily: ["Noto Sans", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Noto Sans", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Noto Sans", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Noto Sans", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Noto Sans", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Noto Sans", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Noto Sans", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("dark");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
