import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider, Box } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Home from "./scenes/home";
import Footer from "./components/Footer";

function App() {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            //minHeight: "100vh",
            //backgroundImage: `url(https://app-notificaciones.conatel.gob.hn/static/media/fondo2.35402b6d500e1db42b2e.jpg)`,
            // backgroundSize: "cover",
            // backgroundPosition: "center",
            // backgroundRepeat: "no-repeat", revisar
            padding: "20px",
          }}
        >
          <div className="app">
            {/* <Sidebar /> */}
            <main className="content">
              {/* <Topbar /> */}
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
              </Routes>
              <Footer />
            </main>
          </div>
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
