import axios from "axios";
let url = "https://api-normativas.conatel.gob.hn/";

export const getListadoNormativas = async (anio) => {
  try {
    const response = await axios.get(url + "listaNormativas/");
    return response.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getDataNormativas = async (anio) => {
  try {
    const response = await axios.get(url + "todasNormativas/" + anio);
    return response.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getAnioNormativas = async () => {
  try {
    const response = await axios.get(url + "aniosNormativas");
    return response.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getNormsModificadasPor = async (codigoResolucion) => {
  try {
    const response = await axios.get(
      url + "normativasModificadaPor/" + codigoResolucion
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getNormsModificadasA = async (codigoResolucion) => {
  try {
    const response = await axios.get(
      url + "normativasModificaA/" + codigoResolucion
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};
