import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";

const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box mb="30px" sx={{ display: "flex" }}>
      <img
        src="https://app-notificaciones.conatel.gob.hn/static/media/LogoNuevo.5292d2e850e2b2b6070a.jpg"
        alt="logo"
        style={{ width: "25%", height: "25%", marginRight: "20px" }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h2"
          color={colors.blackAccent[900]}
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
        >
          {title}
        </Typography>
        <Typography variant="h5" color={colors.blackAccent[900]}>
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default Header;
