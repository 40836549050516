import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";

import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import React, { useEffect, useState } from "react";
import CardNormativa from "../../components/CardNormativas";
import {
  getAnioNormativas,
  getDataNormativas,
  getListadoNormativas,
} from "../../components/Api";

const Home = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [listaNormativas, setListaNormativas] = useState([]);
  const [listaAnios, setListaAnios] = useState([]);
  const [filter, setFilter] = useState("");

  const filteredNormativas = listaNormativas.filter((normativa) => {
    return (
      (normativa.CodigoResolucion &&
        normativa.CodigoResolucion.toLowerCase().includes(
          filter.toLowerCase()
        )) ||
      (normativa.Asunto &&
        normativa.Asunto.toLowerCase().includes(filter.toLowerCase())) ||
      (normativa.Anio &&
        normativa.Anio.toString()
          .toLowerCase()
          .includes(filter.toLowerCase())) ||
      (normativa.Observaciones &&
        normativa.Observaciones.toLowerCase().includes(filter.toLowerCase())) ||
      (normativa.FechaPublicacion &&
        normativa.FechaPublicacion.toLowerCase().includes(
          filter.toLowerCase()
        )) ||
      (normativa.Numero &&
        normativa.Numero.toLowerCase().includes(filter.toLowerCase()))
    );
  });

  useEffect(() => {
    const fetchData = async () => {
      const data = await getListadoNormativas();
      setListaNormativas(data);
    };

    const getAniosNormativas = async () => {
      const data = await getAnioNormativas();
      setListaAnios(data);
      console.log(data);
    };

    fetchData();
    getAniosNormativas();
  }, []);

  const handleFilterChange = (event) => {
    setFilter(event.target?.value);
  };

  const onAnioChange = async (e, value) => {
    const data = await getDataNormativas(value?.value);
    setListaNormativas(data);
  };

  return (
    <Box
      m="20px"
      sx={{
        minHeight: "100vh",
        backgroundImage: `url(https://app-notificaciones.conatel.gob.hn/static/media/fondo2.35402b6d500e1db42b2e.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "repeat",
        padding: "20px",
      }}
    >
      {/*HEADER */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          backgroundColor: colors.grey[100],
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <Header
          title="NORMATIVAS Y REGULACIONES"
          subtitle="Consulta de normativas y regulaciones"
        />
      </Box>

      {/* GRIDs */}
      <Box
        display="grid"
        gap={4}
        alignItems="center"
        gridTemplateColumns="repeat(12, 1fr)"
      >
        <Box
          gridColumn="span 6"
          my={2}
          borderRadius="3px"
          alignItems="center"
          justifyContent="center"
          gridAutoRows="140px"
        >
          <Autocomplete
            disableClearable
            color={colors.blackAccent[900]}
            id="combo-box-demo"
            options={listaAnios}
            sx={{ gridColumn: "span 12" }}
            onChange={onAnioChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Año resolución"
                InputLabelProps={{
                  style: {
                    color: colors.blackAccent[900],
                    borderColor: colors.blackAccent[900],
                  },
                }}
                sx={{
                  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
                    {
                      color: colors.blackAccent[500], // Default text color
                    },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #000000", // Border color
                  },
                  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment':
                    {
                      "& .MuiIconButton-root": {
                        color: colors.blackAccent[900], // Color of the dropdown icon
                      },
                    },
                  "& .MuiAutocomplete-option[data-focus='true']": {
                    backgroundColor: colors.blackAccent[50], // Background color of focused option
                    color: "#000000", // Text color of focused option
                  },
                  "& .MuiAutocomplete-option[aria-selected='true']": {
                    backgroundColor: colors.blackAccent[100], // Background color of selected option
                    color: "#000000", // Text color of selected option
                  },
                }}
              />
            )}
          />
        </Box>
        <Box
          gridColumn="span 6"
          display="flex"
          my={1}
          backgroundColor={colors.white[100]}
          borderRadius="3px"
          alignItems="center"
          justifyContent="center"
        >
          <InputBase
            style={{
              color: colors.blackAccent[900],
            }}
            sx={{ ml: 2, flex: 1 }}
            placeholder="Buscar por palabra clave"
            value={filter}
            onChange={handleFilterChange}
          />
          <IconButton type="button" sx={{ p: 1 }}>
            <SearchIcon
              style={{
                color: colors.blackAccent[900],
              }}
            />
          </IconButton>
        </Box>
      </Box>

      <Box
        sx={{
          display: "grid",
          columnGap: 3,
          rowGap: 1,
          gridTemplateColumns: "repeat(2, 1fr)",
        }}
      >
        {filteredNormativas.map((normativa, index) => (
          <Box key={index} justifyContent="center">
            <CardNormativa
              key={normativa.id}
              Resolucion={normativa.CodigoResolucion}
              Asunto={normativa.Asunto}
              Anio={normativa.Anio}
              Observaciones={normativa.Observaciones}
              FechaPublicacion={normativa.FechaPublicacion}
              Numero={normativa.Numero}
              Estado={normativa.Estado}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Home;
