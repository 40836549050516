import React, { useState } from "react";
import { Box, useTheme, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CiSquareCheck } from "react-icons/ci";
import { AiOutlineCloseSquare } from "react-icons/ai";
import moment from "moment/moment";
import "moment/locale/es";
import { LuBookOpenCheck } from "react-icons/lu";
import { FaFileDownload } from "react-icons/fa";
import { tokens } from "../theme";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { getNormsModificadasA, getNormsModificadasPor } from "./Api";

const CardNormativa = ({ ...props }) => {
  moment.locale("es");
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));
  const [expanded, setExpanded] = useState(false);
  const [listaNormsPor, setListaNormsPor] = useState([]);
  const [listaNormsA, setListaNormsA] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    getNormsModifPor();
    getNormsModifA();
  };
  const handleDownloadClick = () => {
    const link = document.createElement("a");
    link.href =
      "https://www.conatel.gob.hn/doc/Regulacion/resoluciones/" +
      props.Anio +
      "/" +
      props.Resolucion.replace(/\//g, "-") +
      ".pdf";
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getNormsModifPor = async () => {
    const data = await getNormsModificadasPor(
      props.Resolucion.replace(/\//g, "%2F")
    );
    setListaNormsPor(data);
    console.log(props.Resolucion);
  };

  const getNormsModifA = async () => {
    const data = await getNormsModificadasA(
      props.Resolucion.replace(/\//g, "%2F")
    );
    setListaNormsA(data);
  };

  return (
    <Box>
      <Card
        sx={{
          bgcolor: colors.blueAccent[950],
          height: "100%",
        }}
      >
        <CardHeader
          avatar={
            <Avatar
              sx={{
                bgcolor: colors.primary[700],
                color: colors.redAccent[700],
              }}
              aria-label="recipe"
            >
              NR
            </Avatar>
          }
          titleTypographyProps={{
            variant: "h4",
            color: colors.blackAccent[900],
          }}
          title={`Normativa: ${props.Resolucion}`}
          subheader={`Fecha de Publicación: ${moment(
            props.FechaPublicacion
          ).format("LL")}`}
          subheaderTypographyProps={{ color: colors.blackAccent[900] }}
          action={
            <Button
              variant="contained"
              //color="secondary"
              onClick={handleDownloadClick}
              sx={{
                marginLeft: "auto",
                backgroundColor: colors.primary[300],
                color: colors.blackAccent[900],
                "&:hover": {
                  backgroundColor: colors.blueAccent[200],
                },
              }}
              startIcon={<FaFileDownload color={colors.primary[900]} />}
            >
              Descargar
            </Button>
          }
        />
        <CardContent>
          <Typography variant="h6" color={colors.blackAccent[900]}>
            Aspectos Regulados: {props.Asunto}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <Typography variant="h6" color={colors.blackAccent[900]}>
            Estado: {props.Estado}
          </Typography>
          {props.Estado.toLowerCase() === "vigente" ? (
            <CiSquareCheck color={colors.greenAccent[900]} size={25} />
          ) : (
            <AiOutlineCloseSquare color={colors.redAccent[500]} size={25} />
          )}
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            sx={{ color: colors.blackAccent[900] }}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
                gap: 1,
                gridTemplateRows: "auto",
                gridTemplateAreas: `"normA normA normPor normPor"
                  "main main sidebar sidebar "
                  "footer footer footer footer"
                  "msg msg msg msg"
                  "alert alert alert alert"`,
              }}
            >
              <Box sx={{ gridArea: "normA", bgcolor: colors.gov[400] }}>
                <Typography variant="h5" color={colors.white[100]}></Typography>
                <nav aria-label="main mailbox folders">
                  <List
                    subheader={
                      <ListSubheader
                        component="div"
                        sx={{
                          bgcolor: colors.gov[500],
                          fontSize: "15px",
                          color: colors.white[100],
                        }}
                        id="nested-list-subheader"
                      >
                        Normativa que deroga/modifica
                      </ListSubheader>
                    }
                  >
                    {listaNormsA.map((normativaA, index) => (
                      <ListItem key={index} disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <LuBookOpenCheck size={20} />
                          </ListItemIcon>
                          <ListItemText
                            primary={normativaA.CodigoResolucionModificada}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </nav>
              </Box>
              <Box sx={{ gridArea: "normPor", bgcolor: colors.gov[400] }}>
                <nav aria-label="main mailbox folders">
                  <List
                    subheader={
                      <ListSubheader
                        component="div"
                        sx={{
                          bgcolor: colors.gov[500],
                          color: colors.white[100],
                          fontSize: "15px",
                        }}
                        id="nested-list-subheader"
                      >
                        Normativa que la derogó/modificó
                      </ListSubheader>
                    }
                  >
                    {listaNormsPor.map((normativaPor, index) => (
                      <ListItem key={index} disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <LuBookOpenCheck size={20} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              normativaPor.CodigoNormativaQueDerogoModifico
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </nav>
              </Box>
            </Box>
          </CardContent>
        </Collapse>
      </Card>
    </Box>
  );
};
export default CardNormativa;
